<template>
<div>
    <CompanyAddressModal ref="companyAddressModal"></CompanyAddressModal>
    <Confirmation ref="Confirmation"></Confirmation>
      <datatable-heading
        :hasTitleSection="false"
        :changePageSize="changePageSize"
        :searchChange="_.debounce(searchChange, 500)"
        :from="from"
        :to="to"
        :total="total"
        :perPage="perPage"
        :hasPageSizes="false"
        :titleAndButton="true">
        <template v-slot:button>
          <div class="title-and-right-element">
            <PageTitle :title="'Address'" :identifier="'Company #'+ companyID"></PageTitle>
            <div>
            <b-button
              v-b-modal.modalright
              variant="primary"
              size="lg"
              class="top-right-button mr-2"
              @click="addNewCompanyAddress()">
              Add New
            </b-button>
            <b-button
              v-b-modal.modalright
              variant="primary"
              size="md"
              style="font-size: 18px"
              class="simple-icon-trash"
              v-show="isTrashVisible"
              @click="deleteItems()"
            ></b-button>
            </div>
          </div>
        </template>
      </datatable-heading>
      <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
        <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="companyAddressID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded">
              <template slot="address" slot-scope="props">
              <div @contextmenu="rightClicked(props.rowData, '', $event)">
                <div>
                  <p class="text-muted">{{ props.rowData.addressLine1 }}</p>
                  <p class="text-muted">{{ props.rowData.addressLine2 }}</p>
                </div>
              </div>
            </template>
            </vuetable>
          </b-card>
          <vuetable-pagination ref="pagination"
            class="mt-4"
            @vuetable-pagination:change-page="onChangePage"/>
        </b-colxx>
      </b-row>
      </b-overlay>
      <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('edit/view')">
        <i class="simple-icon-docs" />
        <span>Edit/View</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('delete')">
        <i class="simple-icon-trash" />
        <span>Delete</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>
<style scoped>
  label {
    color: #676461;
    font-weight: bold;
  }
</style>
<script>
import { BeaconapiUrl } from '../../../constants/config'
import Confirmation from '../../../components/Common/Confirmation.vue'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import DatatableHeading from '../../../containers/datatable/DatatableHeading'
import CompanyAddressMixin from '../../../mixins/CompanyAddressMixin.vue'
import { validationMixin } from 'vuelidate'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import AddressMixin from '../../../mixins/AddressMixin.vue'
import ParentEntityPageTitle from '../../../components/Common/ParentEntityPageTitle.vue'
import CompanyAddressModal from '../../../components/Modals/CompanyAddressModal.vue'
import { mapMutations, mapActions } from 'vuex'
const {
  required,
  minLength,
  helpers
} = require('vuelidate/lib/validators')

export default {
  props: ['companyID'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: Confirmation,
    PageTitle: ParentEntityPageTitle,
    CompanyAddressModal: CompanyAddressModal
  },
  mixins: [validationMixin, CompanyAddressMixin, AddressMixin],
  data () {
    var _idfield = 'companyAddressID'
    return {
      apiBase: `${BeaconapiUrl}/api/company-address/${this.companyID}/table`,
      sort: '',
      page: 1,
      perPage: 10,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      companyAddressID: 0,
      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right',
          width: '5%'
        },
        {
          name: '__slot:address',
          title: 'Street Address',
          titleClass: '',
          dataClass: 'text-muted',
          width: '30%'
        },
        {
          name: 'city',
          sortField: 'city',
          title: 'City',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'stateCode',
          sortField: 'stateCode',
          title: 'State',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'zip',
          sortField: 'zip',
          title: 'Zip Code',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'addressTypes',
          sortField: 'addressTypes',
          title: 'Address Types',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        }

      ]
    }
  },
  watch: {
    companyAddresses (newVal, oldVal) {
      this.$refs.vuetable.refresh()
    }
  },
  mounted () {
    this.$refs.vuetable.setData(this.companyAddresses)
    const urlParams = new URL(location.href).searchParams
    this.companyAddressID = urlParams.get('addressID')

    if (this.companyAddressID) {
      this.$refs.companyAddressModal.show({
        title: `Edit/View For Address ID: ${this.companyAddressID}`,
        companyAddressID: this.companyAddressID,
        companyID: this.companyID,
        exitFunction: () => {
          // here we want to call replace state when we close the model to remove any addressID in the URL if applicable
          history.replaceState({}, '', location.pathname)
          this.refreshVueTable()
        }
      })
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    onRowClass (dataItem, index) {},
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.$refs.vuetable.selectedTo = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.$refs.vuetable.refresh()
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    rowClicked (dataItem, event) {
      // check to see if we are clicking on the readMore/readLess link, if so do not select or unselect the row.
      if (event.path[0].id.indexOf('readMore') === 0 || event.path[0].id.indexOf('readLess') === 0) {
        const itemId = dataItem[this.$data.idfield]

        if (this.$refs.vuetable.selectedTo.includes(itemId)) {
          this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(
            (x) => x !== itemId
          )
        } else {
          this.$refs.vuetable.selectedTo.push(itemId)
        }
        this.selectedItems = this.$refs.vuetable.selectedTo
      }
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    // delete one or multiple items
    async deleteItems (record) {
      let message = ''; let title = 'Delete Company Address'
      if (record) {
        message = `Are you sure you want to delete ${record}?`
      } else {
        if (this.$refs.vuetable.selectedTo.length > 1) {
          title = 'Delete Company Addresses'
          message = 'Are you sure you want to delete these records?'
        } else {
          message = `Are you sure you want to delete ${this.$refs.vuetable.selectedTo[0]}?`
        }
      }
      this.$refs.Confirmation.show({
        title: title,
        message: message,
        okButton: 'Delete'
      }).then(async result => {
        if (result) {
          // loop through all selected items from the grid
          this.$refs.vuetable.selectedTo.forEach(async item => {
            this.setInfoMessage('')
            // dispatch vuex action to delete each selected service
            await this.deleteCompanyAddress(item)
            if (this.statusCode === 200) {
              this.refreshVueTable()
            }
          })
        }
      })
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    cellclicked (dataitem, field, event) {},
    onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      switch (action) {
        case 'delete': {
          this.deleteItems(rightClickedItem.companyAddressID)
          break
        }
        case 'inactive': {
          break
        }
        case 'edit/view': {
          if (this.$refs.vuetable.selectedTo.length > 1) {
            this.$refs.Confirmation.show({
              title: 'Error',
              message: 'You may only edit one item at a time',
              okButton: 'Cancel',
              exitFunction: (e) => {
                if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
                  this.$refs.vuetable.refresh()
                }
              }
            })
          } else {
            this.$refs.companyAddressModal.show({
              title: `Edit/View For Address ID: ${this.$refs.vuetable.selectedTo[0]}`,
              companyAddressID: this.$refs.vuetable.selectedTo[0],
              companyID: this.companyID,
              exitFunction: () => {
                // here we want to call replace state when we close the model to remove any addressID in the URL if applicable
                history.replaceState({}, '', location.pathname)
                this.refreshVueTable()
              }
            })
            // here we are getting the urlParams object and assigning the
            // corresponding addressID to a url Param and setting it inside of the urlParams object.
            // Then we call replaceState to create the addressID query parameter and adding it to the URL
            const urlParams = new URLSearchParams()
            urlParams.set('addressID', this.$refs.vuetable.selectedTo[0])
            history.replaceState(null, null, '?' + urlParams.toString())
          }
          break
        }
        default:
          break
      }
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    searchChange (val) {
      this.search = val
      this.$refs.vuetable.refresh()
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    async addNewCompanyAddress () {
      this.$refs.companyAddressModal.show({
        title: 'Add New Company Address',
        companyID: this.companyID,
        exitFunction: () => {
          this.refreshVueTable()
        }
      })
    }
  },
  computed: {
    isTrashVisible () {
      return this.selectedItems.length > 0
    }
  }
}
</script>
