<template>
  <b-modal id="modalbasic" size="lg" ref="modalbasic" @hide="close()" v-model="isVisible" :title="this.title"
    :no-close-on-backdrop="true" @ok='close()' @cancel='close()'>
    <messages />
    <b-row>
      <b-col>
        <b-card title="Address Info" icon>
          <b-form>
            <b-row>
              <b-col>
                <b-form-group label="Address Line 1:" label-for="nested-addressLine1">
                  <vue-google-autocomplete
                    id="map"
                    ref="autocomplete"
                    required
                    classname="form-control"
                    placeholder="Search Here..."
                    v-on:placechanged="getAddressData"
                    v-on:change="handleAddressChange"
                    country="us">
                  </vue-google-autocomplete>
                  <div
                    class="text-danger"
                    :state="!$v.companyAddress.addressLine1.$error"
                    v-if="!$v.companyAddress.addressLine1.required"
                  >
                    Field is required!
                  </div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Address Line 2:" label-for="nested-addressLine2">
                  <b-form-input
                    id="nested-addressLine2"
                    class="col-sm-10"
                    v-model.trim="companyAddress.addressLine2"
                    placeholder="Address Line 2"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="City:" label-for="nested-city">
                  <b-form-input
                    id="nested-city"
                    class="col-sm-10"
                    v-model.trim="$v.companyAddress.city.$model"
                    :state="!$v.companyAddress.city.$error"
                    placeholder="City"
                  ></b-form-input>
                  <div
                    class="text-danger"
                    :state="!$v.companyAddress.city.$error"
                    v-if="!$v.companyAddress.city.required"
                  >
                    Field is required!
                  </div>
                   <div class="text-danger"
                    :state="!$v.companyAddress.city.$error"
                    v-if="!$v.companyAddress.city.minLength">Must have at least {{$v.companyAddress.city.$params.minLength.min}} digits.</div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label>State</label>
                  <b-form-select
                    v-model.trim="$v.companyAddress.stateID.$model"
                    :state="!$v.companyAddress.stateID.$error"
                    :options="states"
                    value-field="stateID"
                    text-field="stateCode"
                    plain
                    @input="$v.companyAddress.$touch"></b-form-select>
                  <div class="text-danger" :state="!$v.companyAddress.stateID.$error" v-if="!$v.companyAddress.stateID.required">Field is required</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Zip Code:" label-for="nested-zip">
                  <b-form-input
                    id="nested-zip"
                    class="col-sm-10"
                    v-model.trim="$v.companyAddress.zip.$model"
                    :state="!$v.companyAddress.zip.$error"
                    placeholder="Zip Code"
                  ></b-form-input>
                  <div
                    class="text-danger"
                    :state="!$v.companyAddress.zip.$error"
                    v-if="!$v.companyAddress.zip.required"
                  >
                    Field is required!
                  </div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                <div class="tooltip-label">
                  <label id="address-type-label">Address Type(s)</label>&nbsp;
                  <i class="simple-icon-info" v-b-tooltip.hover.right="{variant:'info'}" title="If there are no Address Types available to select, it is because those Types have already been assigned to an existing Address within this Company.   You will need to remove them from the Address they're assigned to before assigning those Types to this Address."></i>
                </div>
                  <multiselect
                    v-model="companyAddress.addressTypes"
                    :options="addressTypes"
                    :multiple="true"
                    :close-on-select="false"
                    track-by="addressTypeID"
                    label="addressTypeDesc"
                    placeholder="Select Address Type(s)">
                    </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer="{ ok }">
      <b-button
        size="lg"
        variant="outline-danger"
        class="mr-1"
        @click="ok">
        Cancel
      </b-button>
       <api-button-overlay :processing="processing">
      <b-button
        :disabled="$v.$invalid"
        size="lg"
        variant="success"
        class="mr-1"
        @click="saveCompanyAddress()">
        Save
      </b-button>
       </api-button-overlay>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>

<script>

import CompanyAddress from '../../mixins/CompanyAddressMixin.vue'
import { validationMixin } from 'vuelidate'
import { mapMutations } from 'vuex'
import AddressMixin from '../../mixins/AddressMixin.vue'
import Multiselect from 'vue-multiselect'
import ModalMessages from '../../containers/navs/ModalMessages.vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

const {
  required,
  minLength
} = require('vuelidate/lib/validators')

export default {
  name: 'companyAddressModal',
  components: {
    messages: ModalMessages,
    multiselect: Multiselect,
    'vue-google-autocomplete': VueGoogleAutocomplete
  },
  mixins: [CompanyAddress, validationMixin, AddressMixin],

  validations: {
    companyAddress: {
      addressLine1: {
        required
      },
      city: {
        required,
        minLength: minLength(2)
      },
      stateID: {
        required
      },
      zip: {
        required
      }
    }
  },
  data: () => ({
    title: undefined,
    isVisible: false,
    companyAddressID: 0,
    processing: false

  }),

  async created () {
    await this.getStates()
  },

  methods: {
    ...mapMutations([
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      this.title = opts.title

      if (opts.companyAddressID && opts.companyID) {
        this.getAvailableAddressTypesForAddress(opts.companyAddressID, opts.companyID)
      } else if (opts.companyID) {
        // we want to call this first no matter what.  to clear out any data from when/if the modal was opened before.
        this.initNewAddress(opts.companyID)
        this.getAvailableAddressTypesForCompany(opts.companyID)
      }

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      this.isVisible = true
      if (opts.companyAddressID) {
        this.companyAddressID = (opts.companyAddressID)
        await this.getCompanyAddressByCompanyAddressID(this.companyAddressID)
        this.$refs.autocomplete.update(this.companyAddress.addressLine1 || '')
      }
      // if we have an existing companyAddress, get only the address types available to that address.
      // (the types that are not being used by other addresses of this company)
    },

    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.companyAddressID = 0
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    async saveCompanyAddress () {
      if (this.companyAddressID) {
        this.processing = true
        await this.updateCompanyAddress(this.companyAddress)
        this.close()
      } else {
        this.processing = true
        await this.addCompanyAddress(this.companyAddress)
        this.close()
      }
      this.processing = false
    },
    initNewAddress (companyID) {
      this.companyAddress = {
        // companyAddressID: 0,
        companyID: companyID,
        addressTypeID: undefined,
        addressLine1: '',
        addressLine2: '',
        city: '',
        stateID: undefined,
        zip: '',
        addressTypes: undefined
      }
    },
    getAddressData: function (addressData) {
      const { street_number: streetNumber = '', route = '', locality = '', administrative_area_level_1: stateName = '', postal_code: postalCode = '' } = addressData
      const stateId = this.states.find(({ stateCode }) => stateCode === stateName).stateID || ''
      this.companyAddress = {
        ...this.companyAddress,
        addressLine1: `${streetNumber} ${route}`,
        city: locality,
        stateID: stateId,
        zip: postalCode
      }
      // wait some time to handleAddressChange reset the input field
      setTimeout(() => {
        this.$refs.autocomplete.update(`${streetNumber} ${route}`)
      }, 500)
    },
    handleAddressChange: function () {
      this.$refs.autocomplete.update('')
    }
  }
}
</script>
<style>
.tooltip-label{
  display: flex;
  flex-direction: row;
  width: 110px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

#address-type-label{
margin-bottom:0;
}

/* Google Auto complete suggestion adjust  */
.pac-container {
    z-index: 1050;
}
</style>
